@import '@aurora/shared-client/styles/_variables.pcss';

.lia-list-body {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 13px;
}

.lia-list-header {
  font-size: var(--lia-bs-h5-font-size);
}

.lia-modal-filter-wrap {
  padding-bottom: 20px;
  margin: 0 30px;
  border-bottom: 1px solid var(--lia-bs-border-color);

  @media (--lia-breakpoint-down-sm) {
    margin: 0 20px;
    padding-top: 10px;
  }
}
